import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./LoginForm.scss";
import { UserContext } from "../../contexts/UserContext";
import { Button, IconButton, InputAdornment, TextField, Link, Divider, Box, Container } from "@material-ui/core";
import { CCStyles } from "../form/Theme";
import * as UserService from "../../services/UserService";
import doubleGrin from "../../assets//GrinEmojiDoubleA.svg";

import {
    RouteComponentProps,
    useHistory,
    withRouter
} from "react-router-dom";
import { validEmailRegexText } from "../../util/Util";
import {
    ACBL_LOGIN_FLOW_STORAGE_KEY, AcblLoginFlowData
} from "../../pages/acblRegistration/registrationWizard/AcblRegistrationWizard";
import { PhoneNumberOverride } from "../common/ContactPhoneNumber";
import { PlayArrow, Visibility, VisibilityOff } from "@material-ui/icons";

interface LoginElementProps extends RouteComponentProps {
}

function LoginForm(props: LoginElementProps): JSX.Element {
    const { t } = useTranslation('login');
    const { login, getContactPhoneNumber } = useContext(UserContext);

    const acblLoginFlowDataStr = localStorage.getItem(ACBL_LOGIN_FLOW_STORAGE_KEY)
    const acblLoginFlowData: AcblLoginFlowData | null = acblLoginFlowDataStr !== null
        ? JSON.parse(acblLoginFlowDataStr) : null;

    const [email, setEmail] = useState(acblLoginFlowData?.email || "");
    const [password, setPassword] = useState("");
    const [loginResult, setLoginResult] = useState<undefined | string>(undefined);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const classes = CCStyles();
    const history = useHistory();
    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    async function onSubmitLoginForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoginResult(undefined);
        setLoading(true);
        try {
            const isLoggedIn = await login(email, password);
            if (!isLoggedIn) {
                setLoginResult(t('loginForm.notFound', { phoneNumber: getContactPhoneNumber({ override: PhoneNumberOverride.ANONYMOUS }) }));
                setLoading(false);
                return;
            }
            let user = UserService.getUser();
            if (user !== null) {
                localStorage.removeItem(ACBL_LOGIN_FLOW_STORAGE_KEY);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setLoginResult(t('loginForm.unexpectedError'));
            console.error("Error", e);
        }
    }

    return (
        <div>
            <div className="LoginContainer_BigContainer ">
                <div className="VideoContainer">
                    <div className="LoginFormColumnOneHeader">
                        <h1 className="LoginForm_LeftHeader">{t('loginForm.headerForVideo')}
                        </h1>
                    </div>
                    <div>
                    <img className="LoginForm_Video" src={doubleGrin} alt="Grouper Grins icon" />
                    </div>

                    <div style={{display: 'grid', gap: 16, marginTop: 16}}>
                    <div dangerouslySetInnerHTML={{ __html: t('loginForm.videoLine1') }} ></div>

                    <div dangerouslySetInnerHTML={{ __html: t('loginForm.videoLine2') }} ></div>
                    </div>
                </div>
                <Box sx={{display: {xs: 'none', md: 'flex'}, margin: {xs: 0, md: '24px 0px'}}}>
                    <Divider orientation="vertical" />
                </Box>
                <div className="LoginContainer">

                    <div className="LoginForm">
                        <h1 className="LoginHeader">{t('loginForm.header')}</h1>
                        
                        <Box sx={{maxWidth: {xs: '100%', sm: '600px',  lg: '100%'}, alignSelf: {xs: undefined, sm: 'center', lg: undefined}}}>
                        {acblLoginFlowData && <div style={{marginBottom: 20}}>
                            <div className={"LoginForm_AcblLine1"}>{t('loginForm.acblLine1')}</div>
                            <div className={"LoginForm_AcblLine2"}>{t('loginForm.acblLine2')}</div>
                        </div>}
                        {loginResult && <div className="LoginForm_LoginResult">{loginResult}</div>}
                        <form onSubmit={onSubmitLoginForm} className="Form_Container">
                                <TextField
                                 className="LoginForm_Email"
                                    autoFocus
                                    value={email}
                                    type="email"
                                    label={t('loginForm.emailPlaceholder')}
                                    fullWidth={true}
                                    variant="outlined"
                                    inputProps={{
                                        pattern: validEmailRegexText
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'Field',
                                        }
                                    }}
                                    onChange={onChangeEmail}
                                    required
                                />
                                <TextField value={password}
                                    fullWidth={true}
                                    label={t('loginForm.passwordPlaceholder')}
                                    type="password"
                                    variant="outlined"
                                    className={`LoginForm_Password ${classes.root}`}
                                    onChange={onChangePassword} required
                                    InputProps={{
                                        classes: {
                                            input: 'Field',
                                        },
                                        type: showPassword ? 'text' : 'password',
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            <Box style={{gap: 16, display: 'grid', }}>
                                <Box style={{width: '100%', display: 'grid', gap: 8, textAlign: 'center' }}>
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        variant="contained"
                                        color="primary"
                                        className="Button"
                                        startIcon={<PlayArrow />}
                                        fullWidth
                                    >
                                        {t('loginForm.loginButtonText')}
                                    </Button>
                                    <Link style={{fontSize: 14, fontWeight: 'bolder'}} href={"/password-reset"}>
                                        {t('loginForm.forgotPasswordButtonText')}
                                    </Link>
                                </Box>
                                <div className="LoginForm_Or">{t('loginForm.or')}</div>
                                
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className="LoginForm_RegisterButton"
                                    href={"https://hellogrouper.com/join-a-group/?utm_source=product-link&utm_campaign=get-started-v2"}
                                >
                                    {t('loginForm.signUp')}
                                </Button>
                            </Box>

                        </form>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginForm);