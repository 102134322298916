import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import "./PasswordResetConfirmForm.scss";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import {FormControl, IconButton, InputAdornment, Link, TextField} from "@material-ui/core";
import {CCStyles} from "../form/Theme";
import { loadConfig } from "../../services/ConfigService";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function PasswordResetConfirmConfirmForm(): JSX.Element {
    const { t } = useTranslation('passwordReset');
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordResetConfirmResult, setPasswordResetConfirmResult] = useState("");
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [values, setValues] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });


    const classes = CCStyles();

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function onChangePasswordConfirm(event: React.ChangeEvent<HTMLInputElement>) {
        setPasswordConfirm(event.target.value);
    }

    async function onSubmitPasswordResetConfirmForm(event: React.FormEvent<HTMLFormElement>) {
        let token = '';
        let pathParts = window.location.pathname.split("/");
        if (pathParts.length > 2)
        {
            token = pathParts[2];
        }
        event.preventDefault();
        setPasswordResetConfirmResult("");
        if (password !== passwordConfirm) {
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.noMatch'));
            return;
        }
        if (password.length < 8) {
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.invalidLength'));
            return;
        }
        if (password.search(/[A-Z]/) === -1 || password.search(/[0-9]/) === -1) {
            setPasswordResetConfirmResult( t('passwordResetConfirmForm.invalidFormat'));
            return;
        }
        setLoading(true);
        try {
            const config = await loadConfig();
            let passwordResetUrl = `${config.authApiOrigin}/password-reset-confirm`;
            
            const request = new Request(passwordResetUrl, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token,
                    password
                })
            });

            let response = await fetch(request);

            if (response.status === 401) {
            passwordResetUrl = `${config.apiOrigin}/password-reset-confirm`;
            const fallbackRequest = new Request(passwordResetUrl, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token,
                    password
                })
            });
            response = await fetch(fallbackRequest);
            }

            if (response.ok) {
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.success'));
            setComplete(true);
            } else if (response.status === 404 || response.status === 400) {
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.failure'));
            } else {
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.unexpectedError'));
            console.error("Error", response);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
            setPasswordResetConfirmResult(t('passwordResetConfirmForm.unexpectedError'));
            console.error("Error", e);
        }
    }


    return (
        <div className="PasswordResetConfirmForm">
            {!complete &&
                <div>
                    <div className="PasswordResetConfirmPage_Instructions">
                        {t('passwordResetConfirmForm.instructionsTitle')}
                    </div>
                    <div>{t('passwordResetConfirmForm.instructionsText')}</div>
                </div>
                }
            <div className="PasswordResetConfirmForm_Result">{passwordResetConfirmResult}</div>
            <form onSubmit={onSubmitPasswordResetConfirmForm}>
                {!complete && <div className="PasswordResetConfirmForm_FieldContainer">
                    <div className="PasswordResetConfirmForm_Password">
                        <FormControl
                            className={`${classes.root} ${classes.formControl} `}
                            component="fieldset">
                            <TextField
                                value={password}
                                label={t('passwordResetConfirmForm.newPasswordPlaceholder')}
                                fullWidth={true}
                                variant="outlined"
                                className={`${classes.root}`}
                                onChange={onChangePassword} required
                                InputProps={{
                                    type: values.showPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setValues({
                                                        ...values,
                                                        showPassword: !values.showPassword,
                                                    })
                                                }}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="PasswordResetConfirmForm_ConfirmPassword">
                        <FormControl
                            className={`${classes.root} ${classes.formControl} `}
                            component="fieldset">
                            <TextField
                                value={passwordConfirm}
                                label={t('passwordResetConfirmForm.newConfirmPasswordPlaceholder')}
                                fullWidth={true}
                                variant="outlined"
                                className={`${classes.root}`}
                                onChange={onChangePasswordConfirm}
                                required
                                InputProps={{
                                    type: values.showConfirmPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setValues({
                                                        ...values,
                                                        showConfirmPassword: !values.showConfirmPassword,
                                                    })
                                                }}
                                                edge="end"
                                            >
                                                {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </FormControl>
                    </div>
                </div>}
                <div className="PasswordResetConfirmForm_ButtonContainer">
                    {!complete && (
                        <>
                            <Link className="PasswordResetConfirmForm_BackToLoginButton"  href={"/login"}>
                                {t('passwordResetConfirmForm.backToLoginButtonText')}
                            </Link>
                            <PlayButton type="submit" text={t('passwordResetConfirmForm.submitButtonText')}
                                saving={loading}
                                className="PasswordResetConfirmForm_PasswordResetConfirmButton"
                                buttonStyle={ButtonStyles.FilledPrimary}/>
                        </>
                    )}
                    {complete && <PlayButton type="button" text={t('passwordResetConfirmForm.loginButtonText')}
                                             to="/login"
                                className="PasswordResetConfirmForm_LoginButton"
                                buttonStyle={ButtonStyles.FilledPrimary}/>}
                </div>
            </form>
        </div>
    );
}